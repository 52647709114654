export default {
  devise: {
    views: {
      sessions: {
        new: {
          login: "Connexion",
          title: "Connexion",
        },
      },
    },
  },
  helpers: {
    attributes: {
      size: "Taille",
      pitch: "Hauteur",
      dimension: "Dimension",
      color: "Couleur",
    },
    boolean: {
      no: "Non",
      yes: "Oui",
    },
    filters: {
      active: "Activé",
      all: "Tous",
      inactive: "Désactivé",
      sortBy: "Trier par",
    },
    links: {
      back: "Retour",
      cancel: "Annuler",
      delete: "Supprimer",
      edit: "Modifier",
      new: "Ajouter",
      publish: "Publier",
      reset: "Réinitialiser",
      save: "Enregistrer",
    },
    titles: {
      edit: "Modifier {{model}}",
      new: "Nouveau {{model}}",
      newf: "Nouvelle {{model}}",
      newm: "Nouvel {{model}}",
    },
  },
  views: {
    layouts: {
      footer: {
        copyright: "",
      },
      sidebar: {
        logout: "Déconnexion",
        myAccount: "Mon compte",
        title: "Panneau client",
      },
    },
    products: {
      components: {
        productLLMModal: {
          apply: "Appliquer",
          currentVersion: "Version actuelle",
          elementToUse: "Élément à utiliser",
          enhancedVersion: "Version Lightspeed",
          generateAutomatically: "Générer automatiquement",
          instructions: "Instructions",
          parameters: "Paramètres",
          regenerate: "Regénérer",
          source: "Source",
          title: "Générer automatiquement les descriptions",
        },
        productLLMResults: {
          apply: "Appliquer",
          parameters: "Paramètres",
          regenerate: "Regénérer",
          title: "Générer automatiquement les descriptions",
        },
      },
      index: {
        selectAll: "Sélectionner tout",
        sortBy: "Trier par",
        viewMode: "Mode de vue",
        viewModeList: {
          compact: "Vue compacte",
          full: "Vue complète",
          medium: "Vue moyenne",
        },
      },
    },
    shared: {
      controls: {
        deleteButton: {
          alert: {
            archive: {
              content: "L'action suivante archivera <b>{{name}}</b>. Il est possible de le restaurer en cas d'erreur.",
              title: "Êtes-vous sûr?",
            },
            cancel: "Annuler",
            delete: {
              content: "L'action suivante va supprimer <b>{{name}}</b> de façon permanente.",
              title: "Êtes-vous sûr?",
            },
            restore: {
              content: "L'action suivante restaurera <b>{{name}}</b>.",
              title: "Êtes-vous sûr?",
            },
          },
          archive: "Archiver",
          delete: "Supprimer",
          restore: "Restaurer",
        },
        dirtyFormConfirm: {
          cancel: "Annuler",
          confirm: "Continuer",
          content:
            "Votre formulaire contient des modifications non sauvegardées.<br>" +
            "Voulez-vous vraiment poursuivre la navigation?",
          title: "Attention!",
        },
        paginator: {
          multiPage: "Affichage de {{from}} - {{to}} sur {{count}} {{model}}",
          singlePage: {
            one: "Affichage de 1-1 sur 1 {{model}}",
            other: "Affichage de {{count}} {{model}}",
            zero: "Aucune entrée trouvée",
          },
        },
      },
      forms: {
        formFileInput: {
          noFileSelected: "Aucun fichier sélectionné",
          selectFile: "Sélectionner un fichier",
        },
      },
      partials: {
        indexFilters: {
          search: "Rechercher",
          status: "Statut",
        },
      },
    },
    tags: {
      index: {
        amountWithTag: "Nombre d'articles avec cette balise",
      },
    },
  },
};
