import RailsFormat from "@/app/src/i18n/internal/rails.plugin";
import i18next, { type i18n } from "i18next";

const translations = Object.entries(import.meta.glob("../{fr,en}*.ts", { eager: true }))
  .map(([key, value]) => ({
    locale: key.replace("../", "").split(".")[0] as ValidLocale,
    translations: (value as any).default,
  }))
  .reduce(
    (result, { locale, translations }) => ({
      ...result,
      [locale]: {
        translation: {
          ...(result?.[locale]?.["translation"] || {}),
          ...translations,
        },
      },
    }),
    {} as Record<ValidLocale, Record<string, object>>,
  );

const availableLocales = Object.keys(translations) as ValidLocale[];
const initialLocale = localStorage.getItem("app-locale") || document.body.dataset.locale;

export type ValidLocale = "en" | "fr";
export const DEFAULT_LOCALE: ValidLocale = "fr";

i18next.use(RailsFormat).init({
  fallbackLng: DEFAULT_LOCALE,
  lng: initialLocale,
  resources: translations,
});

type UseTranslationOptions = { model?: string; view?: string } | undefined;
type ModelNameOptions = { count: number };

const getRelative = <T>(fn: (args: any) => T, prefix?: string) => {
  return (args: any) => {
    if (prefix && typeof args[0] === "string" && args[0].startsWith(".")) {
      args[0] = `${prefix}${args[0]}`;
    }
    return fn(args);
  };
};

const getRelativeTranslation =
  (prefix?: string) =>
    (...args: Parameters<typeof i18next.t>) =>
      getRelative((args) => i18next.t(...args), prefix)(args);

const getRelativeExistance =
  (prefix?: string) =>
    (...args: Parameters<typeof i18next.exists>) =>
      getRelative((args) => i18next.exists(args), prefix)(args);

const getHumanAttributeName = (defaultModel?: string) => (model: string, key?: string) => {
  if (typeof key === "undefined") {
    key = model;
    model = defaultModel!;
  }
  if (key.startsWith(".")) key = `${model}${key}`;
  return i18next.t(`$$attributes.${key || model}`);
};

const getModelName = (model?: string) => {
  return (name?: ModelNameOptions | string, options?: ModelNameOptions) => {
    if (typeof name === "object") {
      options = name;
      name = model;
    }
    name ||= model;
    options ||= { count: 1 };
    return i18next.t(`$$models.${name}`, options);
  };
};

const getLocalizedAttribute = (defaultLocale: ValidLocale) => {
  return <T extends string>(attribute: T, localeCode?: ValidLocale): `${T}${Capitalize<ValidLocale>}` => {
    const code = localeCode || i18next.language;
    const suffix = (code[0].toUpperCase() + code.slice(1).toLocaleLowerCase()) as "En" | "Fr";
    return `${attribute}${suffix}`;
  };
};

export const useTranslation = ({ model, view }: UseTranslationOptions = {}) => {
  const store = $state({
    attributeName: getHumanAttributeName(model),
    availableLocales: availableLocales,
    locale: i18next.language as ValidLocale,
    localizedAttribute: getLocalizedAttribute(i18next.language as ValidLocale),
    modelName: getModelName(model),
    setLocale: (locale: ValidLocale) => i18next.changeLanguage(locale),
    t: getRelativeTranslation(view),
    exists: getRelativeExistance(view),
    toggleLocale: () => i18next.changeLanguage(i18next.language === "fr" ? "en" : "fr"),
  });

  i18next.on("languageChanged", () => {
    store.t = getRelativeTranslation(view);
    store.exists = getRelativeExistance(view);
    store.attributeName = getHumanAttributeName(model);
    store.modelName = getModelName(model);
    store.locale = i18next.language as ValidLocale;
    store.localizedAttribute = getLocalizedAttribute(i18next.language as ValidLocale);
    localStorage.setItem("app-locale", i18next.language);
  });

  return {
    get attributeName() {
      return store.attributeName;
    },
    get availableLocales() {
      return store.availableLocales;
    },
    get locale() {
      return store.locale;
    },
    get localizedAttribute() {
      return store.localizedAttribute;
    },
    get modelName() {
      return store.modelName;
    },
    get setLocale() {
      return store.setLocale;
    },
    get t() {
      return store.t;
    },
    get exists() {
      return store.exists;
    },
    get toggleLocale() {
      return store.toggleLocale;
    },
  };
};
