import i18next, { type NewableModule, type Services } from "i18next";

type I18nNext = { translator: Services } & typeof i18next;

class RailsFormat {
  private i18next!: I18nNext;

  /**
   * Required by i18next to tell what kind of plugin this is.
   */
  public static type = "i18nFormat";

  /**
   * Invoked by i18next.
   * Add any other locations that should be searched first for an answer to the lookup
   * Add keys to `finalKeys` in reverse order (e.g., least specific -> most specific)
   */
  addLookupKeys(finalKeys: Array<string>, key: string, code: string, ns: string, options: Record<string, any>) {
    this.addPluralKeys(finalKeys, key, options);
    this.addAttributeKeys(finalKeys, key);
    this.addModelKeys(finalKeys, key, options);

    return finalKeys;
  }

  /**
   * Invoked by i18next
   */
  init(i18next: I18nNext) {
    this.i18next = i18next;
  }

  private addAttributeKeys(finalKeys: Array<string>, key: string) {
    const prefix = `$$attributes${this.separator}`;
    if (!key.includes(prefix)) return;

    const model = key.split(prefix)[1].split(".")[0];

    finalKeys.push(`${key.replace(`${prefix}${model}`, `attributes${this.separator}defaults`)}`);
    finalKeys.push(`${key.replace(prefix, `attributes${this.separator}`)}`);
  }

  private addModelKeys(finalKeys: Array<string>, key: string, options: Record<string, any>) {
    const prefix = `$$models${this.separator}`;
    if (!key.includes(prefix) || typeof options.count !== "number") return;

    const model = key.split(prefix)[1];

    if (options.count === 1) {
      finalKeys.push(`${key.replace(`${prefix}${model}`, `models${this.separator}${model}${this.separator}other`)}`);
      finalKeys.push(`${key.replace(`${prefix}${model}`, `models${this.separator}${model}${this.separator}one`)}`);
    } else {
      finalKeys.push(`${key.replace(`${prefix}${model}`, `models${this.separator}${model}${this.separator}one`)}`);
      finalKeys.push(`${key.replace(`${prefix}${model}`, `models${this.separator}${model}${this.separator}other`)}`);
    }
  }

  private addPluralKeys(finalKeys: Array<string>, key: string, options: Record<string, any>) {
    if (key.includes("$$models")) return;
    if (typeof options.count !== "number") return;

    // const pluralRule = this.i18next.translator.pluralResolver.getRule(code, options);
    // const ruleName = pluralRule.select(options.count);

    // Other is the least specific key.
    finalKeys.push(`${key}${this.separator}other`);

    // Explicit zero and one.
    if (options.count === 0) {
      finalKeys.push(`${key}${this.separator}zero`);
    } else if (options.count === 1) {
      finalKeys.push(`${key}${this.separator}one`);
    }
  }

  private get separator(): string {
    return this.i18next.options.keySeparator || ".";
  }
}

export default RailsFormat as unknown as NewableModule<{ type: "i18nFormat" }>;
