import type { Page } from "@jamesst20/inertia-core";
import type { ComponentType } from "svelte";

import "@/app/src/i18n/internal/i18n.svelte";
import { createInertiaApp } from "@jamesst20/inertia-svelte5";
import { hydrate, mount } from "svelte";

import type { ComponentImport, GlobImport } from "./types/vite";

const getLayout = async (layouts: GlobImport, component?: ComponentImport) => {
  // Layout can be defined inside the component using an export.
  if (component?.layout === false) return;
  if (Array.isArray(component?.layout) && component?.layout?.length === 0) return;
  if (component?.layout) return component.layout as ComponentType;

  // Default layout
  return (await getComponent(layouts, "layouts/AppLayout")).default;
};

const getComponent = (components: GlobImport, path: string): Promise<ComponentImport> => {
  const component = components[`./src/views/${path}.svelte`];

  if (!component) {
    return Promise.reject(`Error: View for route "${path}" not found.`);
  }

  if (typeof component === "function") {
    return component();
  } else {
    return component;
  }
};

type CreateAppParams = { initialPage: Page; routes: GlobImport };
export const createApp = ({ initialPage, routes }: CreateAppParams) =>
  createInertiaApp({
    page: initialPage,
    resolve: async (name: string) => {
      const component = name ? await getComponent(routes, `${name}`) : undefined;
      return {
        default: component?.default,
        layout: await getLayout(routes, component),
      };
    },
    setup({ App, el }: any) {
      if (el.dataset.serverRendered === "true") {
        hydrate(App, { target: el });
      } else {
        mount(App, { target: el });
      }
    },
  });
