import type { GlobImportLazy } from "./src/types/vite";

import { createApp } from "./src/app";

const routes: GlobImportLazy = import.meta.glob("./src/views/**/*.svelte", { eager: false }) as GlobImportLazy;

createApp({
  initialPage: JSON.parse(document.getElementById("app")!.dataset.page!),
  routes,
});
